@use '../../scss/' as *;


.tf-about {
    padding: 80px 0 55px;
    .tf-title {

        &.st2 {
            margin-bottom: 17px;
            text-align: left;
        }
    }
    .content-about {
        
        .social {
            @include flex2;
            margin-top: 47px;
            li {
                @include flex(center,center);
                margin-right: 24px;
                width: 68px;
                height: 68px;
                &.corner-box {
                    background: #0e2230;
                    &::after,
                    &::before {
                        background: #0e2230;
                    }
                    &::before {
                        width: calc(130% - 3rem);
                    }
                    &::after {
                        height: calc(130% - 3rem);
                    }
                }
                &:hover {
                    box-shadow: $box-shadow;
                }
            }
        }
    }
    .tf-button {
        margin-top: 44px;
        height: 64px;
        line-height: 64px;
        padding: 0 33px;
    }
    &.style2 {
        padding: 90px 0 80px;
        .container {
            max-width: 960px;
            .content-about {
                text-align: center;
            }
            .btn-about {
                text-align: center;
            }
        }
    }
}
.ai-trading {
  background: $bg-main;
  padding: 20px;
  border: 1px solid $color-main2;
  border-radius: 10px;
  color: $txt-color;

  h2 {
    color: $white;
    margin-bottom: 10px;
  }

  .trading-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    label {
      color: $white;
    }

    select, input {
      padding: 10px;
      border: 1px solid $color-main2;
      border-radius: 5px;
      background: $bgr-body;
      color: $txt-color;
    }

    button {
      padding: 10px;
      border: none;
      border-radius: 5px;
      background: $color-hover;
      color: $white;
      cursor: pointer;

      &:hover {
        background: darken($color-hover, 10%);
      }
    }
  }

  .trade-details {
    margin-top: 10px;
    background: $bg-3;
    padding: 10px;
    border-radius: 5px;

    p {
      margin: 5px 0;
      color: $txt-color;
    }
  }

  .trades {
    h3 {
      color: $white;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        background: $bg-3;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        color: $txt-color;
      }
    }
  }
}
