@use '../../scss/' as *;

.blog .tf-button {
    height: 66px;
    line-height: 64px;
    padding: 0 33px;
}

.home-2 .blog , .home-3 .blog {
    .swiper {
        padding-top: 91px;
        margin-top: -91px;

        .swiper-button-next , .swiper-button-prev {
            top: 23px;

            @include mobile {
                display: none;
            }
        }
        .swiper-button-next {
            right: -12px;
        }
        .swiper-button-prev {
            left: 92%;

            @include tablet {
                left: 86%;
            }
        }
    }
}

.home-2 .blog {
    padding: 88px 0 100px;
}

.home-3 .blog {
    padding: 88px 0 99px;
}

.post-details {
    .blog {
        padding: 0 0 84px;

        .tf-title.st2 {
            text-align: center;
            margin-bottom: 52px;
        }

        .content-item .content {
            .h7 {
                margin-bottom: 0;
            }
            p {
                display: none;
            }
        }
    }
}

.stake-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.stake-card {
    background: $bg-3;
    border: $border-width solid $color-main2;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: $box-shadow;
}

.stake-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
        color: $txt-color;
        font-family: $font-main;
        font-size: 1.5rem;
    }

    .stake-period {
        color: $color-hover;
        font-family: $font-main;
        font-size: 1.5rem;
    }
}

.apr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg-4;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;

    span {
        color: $color-hover;
        font-family: $font-main;
        font-size: 1.5rem;
    }

    p {
        color: $txt-color;
        font-family: $font-main;
        font-size: 1rem;
    }
}

.stake-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    h4 {
        color: $txt-color;
        font-family: $font-main;
        font-size: 1rem;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .token-select {
        padding: 0.25rem;
        border: $border-width solid $color-main2;
        border-radius: 5px;
        font-family: $font-main;
        font-size: 0.875rem;
        color: $txt-color;
        background: $bg-3;
        appearance: none;
        margin-bottom: 20px;

        &:focus {
            outline: none;
            border-color: $bg-2;
        }
    }

    .btn {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        font-family: $font-main;
        font-size: 1rem;
        color: $white;
        cursor: pointer;
        margin-top: 10px;
        width: 100%;

        &.stake-btn {
            background: $bg-2;
        }

        &.withdraw-btn {
            background: $color-hover;
        }
    }
}

.pending-rewards, .total-rewards {
    background: $bg-4;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;

    h4, h5 {
        color: $txt-color;
        font-family: $font-main;
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .rewards-box, .rewards-total {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $bg-3;
        padding: 10px;
        border-radius: 5px;
        color: $txt-color;
        font-family: $font-main;
        font-size: 1rem;
    }

    img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }
}
.stake-period {
    padding: 0.25rem;
    border: $border-width solid $color-main2;
    border-radius: 5px;
    font-family: $font-main;
    font-size: 0.875rem;
    color: $txt-color;
    background: $bg-3;
    appearance: none;
    margin-bottom: 20px;

    &:focus {
        outline: none;
        border-color: $bg-2;
    }
}

.approvals-section {
    margin-top: 20px;

    h4 {
        color: $txt-color;
        font-family: $font-main;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $bg-3;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;

            div {
                color: $txt-color;
                font-family: $font-main;
                font-size: 1rem;
            }

            .btn.withdraw-btn {
                background: $color-hover;
                margin: 0;
                padding: 0.5rem 1rem;
            }
        }
    }
}
